import React, { useEffect } from 'react';
import Datas from '../data/help-area/help-area.json';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/homeContact.js";

function HelpArea() {
    return (
        <Styles>
            {/* Help Area */}
            <section className="home-contact-area" style={{ backgroundImage: `url(./assets/images/aronai.png)` }}>
                <Container>
                    <Row>
                        <Col md="5">
                            <div className="sec-title mt-5">
                                <h4>Welcome to Director of Education  Bodoland Territorial Council</h4>
                            </div>
                        </Col>
                        <Col md="7">
                        <p className='text-white text-justify'>
                            The Education Department is the transferred subject to the BTC and is the Nodal Department for proper Administration and maintenance of Educational Sector under the BTR Administrative area. The office of the Director Education, BTC being the Council Head of Department is the overall authority for academic and management of the entire Education department of BTR. All the Sectors of the Education department under BTR are looked after by the Director of Education, BTC from Elementary to Higher (College) level of Education including Sanskrit Tolls andMadrasas, except the Technical Education and University. The BTC Secretariat along with Executive Member, Education Department,BTR is the Administrative Head of the Departmentwho take policy directions for the entire department of Education for implementation.
                        </p>                           
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    );
}

export default HelpArea
